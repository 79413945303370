import { GridColumnModel } from "./project-grid-columns";
export const projectTaskColumnStatus = "flexTaskStatus";
export const projectTaskColumnCollaborator =
  "e2EProjectLineFlexTaskCollaborators";
export const projectTaskColumnName = "flexTaskName";
export const projectTaskStartDate = "startDate";
export const projectTaskDueDate = "dueDate";
export const projectTaskCompletionDate = "completionDate";
export const projectTaskTotalHours = "totalHours";
export const projectTaskMyHours = "myHours";
export const projectTaskWBS = "workBreakdownStructure";

export const projectTaskListColumns: GridColumnModel[] = [
  {
    id: projectTaskWBS,
    value: "WBS",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "70px",
    widthNum: 70,
    orderNo: 1,
    align: "center",
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 1,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: projectTaskColumnName,
    value: "Task Name",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "470px",
    widthNum: 470,
    orderNo: 2,
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 2,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: projectTaskColumnStatus,
    value: "GMAWB Task Status",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 200,
    orderNo: 3,
    isFlexWidth: true,
    orderNoShowHide: 3,
    canHide: true,
  },
  {
    id: projectTaskColumnCollaborator,
    value: "Contributors",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "250px",
    widthNum: 250,
    orderNo: 4,
    isFlexWidth: false,
    orderNoShowHide: 4,
    canHide: true,
  },
  {
    id: projectTaskTotalHours,
    value: "Total Hours",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 5,
    isFlexWidth: true,
    orderNoShowHide: 5,
    canHide: true,
  },
  {
    id: projectTaskMyHours,
    value: "My Hours",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 6,
    isFlexWidth: true,
    orderNoShowHide: 6,
    canHide: true,
  },
  {
    id: projectTaskStartDate,
    value: "Start Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 7,
    isFlexWidth: true,
    orderNoShowHide: 7,
    canHide: true,
  },
  {
    id: projectTaskDueDate,
    value: "Due Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 8,
    isFlexWidth: true,
    orderNoShowHide: 8,
    canHide: true,
  },
  {
    id: projectTaskCompletionDate,
    value: "Completion Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 9,
    isFlexWidth: true,
    orderNoShowHide: 9,
    canHide: true,
  },
];
