import { createSlice } from "@reduxjs/toolkit";
import { CustomerModel } from "../../shared/models/Customer.model";
import {
  E2EProjectLineModel,
  E2EProjectModel,
} from "../../shared/models/E2EProject.model";
import {
  ProjectWorkQueue,
  ProjectWorkQueueWrapper,
} from "./projectWorkQueueSliceInterface";
import { SelectListResultModel } from "../../shared/service-models/SelectListResult.mode";

const initialState: ProjectWorkQueue = {
  e2eProject: {
    name: "",
    status: 0,
    customer: {
      name: "",
      normalizedName: "",
      accountNumber: "",
      status: 0,
    } as CustomerModel,
    e2EProjectLines: [] as E2EProjectLineModel[],
    initialE2EProjectLines: [] as E2EProjectLineModel[],
  } as E2EProjectModel,
  e2eProjectList: [] as E2EProjectModel[],
  applicant: {
    companyName: "",
    partySiteNumber: "",
  },
  productOwner: {
    companyName: "",
    partySiteNumber: "",
  },
  favoritesModel: {
    recordsPerPage: 0,
    refiner: {},
    columns: {},
    customSearchText: "",
    frozenCount: 0,
  },
  originalProjectName: "",
  flexTemplateConfirmationLines: []
};

export const createProjectWorkQueueSlice = createSlice({
  name: "projectWorkQueue",
  initialState: initialState as ProjectWorkQueue,
  reducers: {
    resetProjectWorkQueue: () => initialState,
    resetProjectList: (state) => {
      state.e2eProjectList = [] as E2EProjectModel[];
    },
    resetProjectLines: (state) => {
      state.e2eProject.e2EProjectLines = [] as E2EProjectLineModel[];
    },
    resetInitialProjectLines: (state) => {
      state.e2eProject.initialE2EProjectLines = [] as E2EProjectLineModel[];
    },
    updateE2EProject: (state, action) => {
      state.e2eProject = action.payload;
    },
    updateE2EProjectId: (state, action) => {
      state.e2eProject.id = action.payload;
    },
    updateE2EProjectName: (state, action) => {
      state.e2eProject.name = action.payload;
    },
    updateCustomer: (state, action) => {
      state.e2eProject.customer = action.payload;
    },
    updateE2EProjectList: (state, action) => {
      state.e2eProjectList = action.payload;
    },
    updateCustomerLocation: (state, action) => {
      state.e2eProject.customerLocation = action.payload;
    },
    updateCustomerContact: (state, action) => {
      state.e2eProject.customerContact = action.payload;
    },
    updateProjectLines: (state, action) => {
      state.e2eProject.e2EProjectLines = action.payload;
    },

    refreshBulkRow: (state) => {
      state.e2eProject.e2EProjectLines = state.e2eProject.e2EProjectLines?.map(
        (x) => {
          if (x.flexProjectLineId === "0") {
            let bulkRefApplicationTypeList: Array<SelectListResultModel> | null =
              [];
            let bulkRefDeliveryPathList: Array<SelectListResultModel> | null =
              [];
            let bulkRefAdditionalInfoList: Array<SelectListResultModel> | null =
              [];
            state.e2eProject.e2EProjectLines?.forEach((projectLines) => {
              projectLines.applicationTypeList?.forEach((applicationType) => {
                if (
                  !bulkRefApplicationTypeList?.find(
                    (x) => x.id == applicationType.id
                  )
                ) {
                  bulkRefApplicationTypeList?.push(applicationType);
                }
              });

              projectLines.deliveryPathList?.forEach((deliveryPath) => {
                if (
                  !bulkRefDeliveryPathList?.find((x) => x.id == deliveryPath.id)
                ) {
                  bulkRefDeliveryPathList?.push(deliveryPath);
                }
              });

              projectLines.additionalInfoList?.forEach((additionalInfo) => {
                if (
                  !bulkRefAdditionalInfoList?.find(
                    (x) => x.id == additionalInfo.id
                  )
                ) {
                  bulkRefAdditionalInfoList?.push(additionalInfo);
                }
              });
            });

            const bulkRowItem: any = {
              applicationTypeList: bulkRefApplicationTypeList,
              deliveryPathList: bulkRefDeliveryPathList,
              additionalInfoList: bulkRefAdditionalInfoList,
            };

            return {
              ...x,
              ...bulkRowItem,
            };
          }

          return x;
        }
      );
    },
    patchProjectLine: (state, action) => {
      state.e2eProject.e2EProjectLines = state.e2eProject.e2EProjectLines?.map(
        (x) => {
          if (action.payload.flexProjectLineId === x.flexProjectLineId) {
            return {
              ...x,
              ...action.payload,
            };
          }

          return x;
        }
      );
    },
    updateInitialE2EProjectLines: (state, action) => {
      state.e2eProject.initialE2EProjectLines = action.payload;
    },
    updateApplicant: (state, action) => {
      state.applicant = action.payload;
    },
    updateProductOwner: (state, action) => {
      state.productOwner = action.payload;
    },
    updateFavoritesModel: (state, action) => {
      let newItem = JSON.parse(JSON.stringify(state.favoritesModel));

      newItem[action.payload.property] = action.payload.value;
      state.favoritesModel = newItem;
    },
    updateOriginalProjectName: (state, action) => {
      state.originalProjectName = action.payload;
    },
    updateFlexTemplateConfirmationLines: (state, action) => {
      state.flexTemplateConfirmationLines = action.payload;
    },
    patchFlexTemplateConfirmationLine: (state, action) => {
      state.flexTemplateConfirmationLines = state.flexTemplateConfirmationLines?.map(
        (flexTemplateConfirmationLine) => {
          if (action.payload.flexProjectLineId === flexTemplateConfirmationLine.flexProjectLineId) {
            return {
              ...flexTemplateConfirmationLine,
              ...action.payload,
            };
          }
          return flexTemplateConfirmationLine;
        }
      );
    },
  },
});

export const {
  resetProjectWorkQueue,
  resetProjectList,
  resetProjectLines,
  resetInitialProjectLines,
  updateE2EProject,
  updateE2EProjectId,
  updateE2EProjectName,
  updateE2EProjectList,
  updateCustomer,
  updateCustomerLocation,
  updateCustomerContact,
  updateProjectLines,
  refreshBulkRow,
  patchProjectLine,
  updateApplicant,
  updateProductOwner,
  updateFavoritesModel,
  updateInitialE2EProjectLines,
  updateOriginalProjectName,
  updateFlexTemplateConfirmationLines,
  patchFlexTemplateConfirmationLine
} = createProjectWorkQueueSlice.actions;

export const selectProjectWorkQueue = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject;

export const selectE2EProjectList = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProjectList;

export const selectCustomer = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.customer;

export const selectCustomerLocation = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.customerLocation;

export const selectCustomerContact = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.customerContact;

export const selectProjectLines = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.e2EProjectLines;

export const selectApplicant = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.applicant;

export const selectProductOwner = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.productOwner;

export const selectSelectFavoritesModel = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.favoritesModel;

export const selectInitialE2EProjectLines = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.initialE2EProjectLines;

export const selectE2EProjectName = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.name;

export const selectE2EProjectId = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.e2eProject.id;

export const hasSelectedE2EProjectId = (state: ProjectWorkQueueWrapper) =>
  !!state.projectWorkQueue.e2eProject.id &&
  state.projectWorkQueue.e2eProject.id !== "0";

export const selectOriginalProjectName = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.originalProjectName;

export const selectFlexTemplateConfirmationLines = (state: ProjectWorkQueueWrapper) =>
  state.projectWorkQueue.flexTemplateConfirmationLines;

export default createProjectWorkQueueSlice.reducer;
