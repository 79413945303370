import { authProvider } from "../providers/authProvider";
import RequestFactory from "./RequestFactory";

const certificateUrl = process.env.REACT_APP_CERTIFICATE_URL;
const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const CertificateListService = {
  getCertificateScheme: async (data) =>
    await RequestFactory.get(
      certificateUrl,
      "/ipintegration/get-ip-certificate-scheme"
    ),
  getCertificateListData: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/get-ip-certificate-list",
      data
    ),
  getIpProductListData: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/get-ip-product-list",
      data
    ),
  getCertificateAttributes: async (scheme, hierarchyId) =>
    await RequestFactory.get(
      certificateUrl,
      `/ipintegration/get-ip-certificate-attributes?certificateScheme=${scheme}&hierarchyId=${hierarchyId}`
    ),
  getCertificateStatistics: async () =>
    await RequestFactory.get(
      certificateUrl,
      "/ipintegration/get-ip-certificate-statistics"
    ),
  getIpCertificateRefiners: async (refiner, search, config) =>
    await RequestFactory.get(
      certificateUrl,
      `/ipintegration/get-ip-certificate-refiners?refiner=${refiner}&search=${search}`,
      config
    ),
  getIpAttributesDropdownList: async (attributeType, search) =>
    await RequestFactory.get(
      certificateUrl,
      `/ipintegration/get-ip-attributes-dropdown-list?attributeType=${attributeType}&search=${search}`
    ),
  getIpStandardDetails: async (attributeType, standardScopeId) =>
    await RequestFactory.get(
      certificateUrl,
      `/ipintegration/get-ip-attributes-dropdown-list?attributeType=${attributeType}&schemeScopeStandardId=${standardScopeId}`
    ),
  deleteIPCertificate: async (certificateId) =>
    await RequestFactory.post(certificateUrl, "/ipintegration/delete", {
      certificateId,
    }),
  addCertAttachment: async (formData, uploadProgressEvent) =>
    await RequestFactory.upload(
      certificateUrl,
      `/certificate/add-attachment`,
      formData,
      uploadProgressEvent
    ),
  deleteAttachment: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/certificate/delete-attachment",
      data
    ),
  saveFavorites: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/favorites/upsert-view-favorite-settings",
      data
    ),
  getFavorites: async (id) =>
    await RequestFactory.get(
      certificateUrl,
      `/favorites/list-saved-view-favorites?viewTypeId=${id}`
    ),
  getCertificateDetails: async (certId) =>
    await RequestFactory.get(
      certificateUrl,
      `/ipintegration/get-ip-certificate-details?certificateId=${certId}`
    ),
  ipCertificateWriteback: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/ip-certificate-writeback",
      data
    ),
  ipCertificateWritebackV2: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/ip-certificate-writeback-v2",
      data
    ),
  certificateSaveAndUploadECM: async (data) =>
    await RequestFactory.upload(
      GMAE2EURL,
      "/api/certificate/create-certificate",
      data
    ),
  certificateUpdateAndUploadECM: async (data) =>
    await RequestFactory.upload(
      GMAE2EURL,
      "/api/certificate/update-certificate",
      data
    ),
  certificateAttachmentSaveLinkUploadECM: async (data) =>
    await RequestFactory.upload(
      GMAE2EURL,
      "/api/certificate/certificate-document/link-to-wb-and-ecm",
      data
    ),
  certificateAttachmentSingleLinkUploadECM: async (data) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/certificate-document/single-link-to-wb-and-ecm",
      data
    ),
  activateCertificate: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/activate-certificate",
      data
    ),
  withdrawCertificate: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/withdraw-certificate",
      data
    ),
  modifyCertificate: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/modify-certificate",
      data
    ),
  modifyCertificateV2: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/modify-certificate-v2",
      data
    ),
  updateAttachments: async (data) => {
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/save-attached-file",
      data
    );
  },
  uploadBulkImportTemplate: async (formData, uploadProgressEvent) =>
    await RequestFactory.upload(
      certificateUrl,
      `/ipintegration/bulk_upload_certificate`,
      formData,
      uploadProgressEvent
    ),
  exportToSpreadSheet: async (data) =>
    await RequestFactory.download(
      certificateUrl,
      `/ipintegration/export-ip-certificate`,
      data
    ),
  deleteStoreCertificate: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/certificate/delete-store-certificate",
      data
    ),
  remapModel: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/ip-remap-models",
      data
    ),
  downloadCertificateAttachment: async (data) =>
    await RequestFactory.download(
      certificateUrl,
      "/ipintegration/download-certificate-attachment",
      data
    ),
  generateCertficateNumber: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/generate-certificate-number",
      data
    ),
  getCertificateProjectHistory: async (data) =>
    await RequestFactory.post(
      certificateUrl,
      "/ipintegration/get-certificate-project-history?certificateId=" + data,
      data
    ),
  getCertificateDocuments: async (certificateId) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/certificate/certificate-document/${certificateId}`
    ),
  getCertificateDocumentsByUniqueCertificateId: async (uniqueCertificateId) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/certificate/certificate-document/uniqId/${uniqueCertificateId}`
    ),
  getCertificateDocumentsFailedEcmUpload: async (data) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/certificate-document/upload-failed/list",
      data
    ),
  downloadCertificateDocument: async (documentId) =>
    await RequestFactory.downloadGet(
      GMAE2EURL,
      `/api/certificate/certificate-document/download/${documentId}`
    ),
  getCertificateDetailByWorkbenchCertificateId: async (
    workbenchCertificateId
  ) =>
    await RequestFactory.get(
      GMAE2EURL,
      "/api/certificate/certificate-detail-by-wb-cert/" + workbenchCertificateId
    ),
  unlinkEcmDocument: async (model) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/ecm-document/unlink",
      model
    ),
  deleteFileFromBlob: async (fileId) =>
    await RequestFactory.post(
      GMAE2EURL,
      `/api/certificate/certificate-document/delete/${fileId}`
    ),
  getCertificateDetailsForActivation: async (workbenchCertificateId) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/certificate/certificate-for-activation/${workbenchCertificateId}`
    ),
  updateActivatedCertificateDetails: async (workbenchCertificateId) =>
    await RequestFactory.patch(
      GMAE2EURL,
      `/api/certificate/certificate-detail/update-activated-certificate-detail/${workbenchCertificateId}`
    ),
  getHasCertificateBeenActive: async (certificateId) => {
    return await RequestFactory.get(
      GMAE2EURL,
      "/api/certificate/has-certificate-been-active?certificateId=" +
        certificateId
    );
  },
  linkWb: async (model) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/ecm-document/link",
      model
    ),
  reuploadAndLinkDocsBatch: async (param) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/certificate-document/reupload-and-link-docs-batch",
      param
    ),
  deleteBatchCertificateDocumentsFailedEcmUpload: async (data) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/certificate-document/delete-batch",
      data
    ),
  getDuplicateDocuments: async (data) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/certificate/certificate-document/documents-duplicate",
      data
    ),
  getCertificateDetailsWithDocuments: async (certificateId) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/certificate/certificate-detail/${certificateId}`
    ),
  
    getCertificateDetailsRedirect: async (payload) => 
      await RequestFactory.get(
        GMAE2EURL,
        `/api/certificate/get-certificate-details-redirect?e2EProjectId=${payload.e2EProjectId}&e2EProjectLineId=${payload.e2EProjectLineId}&e2EProjectLineFlexTaskId=${payload.e2EProjectLineFlexTaskId}`
      )
};

export default CertificateListService;
