import { useState, useEffect } from "react";
import WithdrawCertificateModal from "../../../../../shared/withdraw-certificate-modal/WithdrawCertificateModal";
import { useDispatch } from "react-redux";
import GridActionPopover from "../../../../../shared/grid-action-popover/GridActionPopover";
import {
  faCopy,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArchive,
  faCheckCircle,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  updateProdDetailsAttachments,
  updateProdDetailsModelList,
  updatePartySiteNumber,
  updateSelectedProjectDetails,
  updateModelList,
  updateCertificateScheme,
  updateLineDetails,
  updateOrderLineNumbers,
  updateSelectedOrderLineNumber,
} from "../../../../../features/createCertificate/createCertificateSlice";
import CertificateListService from "../../../../../services/CertificateListService";
import ProjectDetailsService from "../../../../../services/ProjectDetailsService";
import AddNewCertificate from "../../add-new-certificate/AddNewCertificate";
import CopyCertificateModal from "../../../../../shared/copy-certificate-modal/CopyCertificateModal";
import { flexProjectContactRole } from "../../../../../utils/constants/flex-project-contact-role.constants";
import FeatureFlagService from "../../../../../services/FeatureFlagService";
import useToastr from "../../../../../hooks/useToastr";
import { CertificateDocumentStatus } from "../../../../../enums/CertificateDocumentStatus";
const GridAction = ({
  setLoading,
  childPopOverRef,
  selectedData,
  onActivateCertificate,
  setShowCertificateDetails,
  onDeleteCertificate,
  isReadOnly,
}) => {
  const { showInfo } = useToastr();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [details, setDetails] = useState(null);
  const [displayAddNewCertModal, setDisplayAddNewCertModal] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [isModifyCert, setIsModifyCert] = useState(false);
  const [isOpenCopy, setIsOpenCopy] = useState(false);
  const [certificateId, setCertificateId] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [revisionNumber, setRevisionNumber] = useState("");
  const [hasRevisionNumber, setHasRevisionNumber] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [attachments, setAttachments] = useState(null);

  const hasPendingTestReportFailedMessage =
    "Please finish linking all documents in this certificate's flyout window or in the Pending Documents List page before attempting to modify this certificate.";
  useEffect(() => {
    let newActionsList = [];

    if (selectedData?.certificateStatus == "Active") {
      newActionsList = [
        { textDisplay: "Modify", icon: faPencilAlt, action: modifyCertificate },
        { textDisplay: "Create Copy", icon: faCopy, action: copyCertificate },
        {
          textDisplay: "Withdraw",
          icon: faArchive,
          action: withdrawCertificate,
        },
      ];
    }

    if (selectedData?.certificateStatus == "Under Revision") {
      newActionsList = [
        { textDisplay: "Edit", icon: faEdit, action: editCertificate },
        {
          textDisplay: "Activate",
          icon: faCheckCircle,
          action: activateCertificate,
        },
        { textDisplay: "Create Copy", icon: faCopy, action: copyCertificate },
      ];

      if (!isReadOnly) {
        newActionsList.push({
          textDisplay: "Delete",
          icon: faTrashAlt,
          action: () => onDeleteCertificate(selectedData),
        });
      }
    }

    if (
      selectedData?.certificateStatus == "Withdrawn" ||
      selectedData?.certificateStatus == "Obsolete"
    ) {
      newActionsList = [
        { textDisplay: "Create Copy", icon: faCopy, action: copyCertificate },
      ];
    }

    setActionList(newActionsList);
  }, [selectedData]);

  const dialogFuncMap = {
    displayAddNewCertModal: setDisplayAddNewCertModal,
  };

  const onShowModal = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const activateCertificate = async () => {
    setIsDisable(true);
    await onActivateCertificate(selectedData);
    setIsDisable(false);
  };

  const withdrawCertificate = async () => {
    setLoading(true);
    const result = await CertificateListService.getCertificateAttributes(
      selectedData.certificateType,
      ""
    );

    if (result[0]) {
      const certificateAttributes = result[0]["cert_attributes"];

      if (certificateAttributes) {
        const revision = certificateAttributes.find(
          (a) => a.attribute_name === "revisionNumber"
        );

        if (revision) {
          setHasRevisionNumber(true);
        } else {
          setHasRevisionNumber(false);
        }
      }
    }

    setCertificateId(selectedData.certificateId);
    setCertificateName(selectedData.certificateName);
    setRevisionNumber(selectedData.revisionNumber);
    setIsOpen(true);
    setLoading(false);
  };

  const editCertificate = async () => {
    var hasPendingTestReport = await loadDetails();
    if (hasPendingTestReport) {
      showInfo(
        "Continue Linking of Documents before Editing Certificate",
        hasPendingTestReportFailedMessage
      );
    } else {
      setModalHeader("Edit Certificate");
      setIsModifyCert(false);
    }
  };

  const modifyCertificate = async () => {
    var hasPendingTestReport = await loadDetails();

    if (hasPendingTestReport) {
      showInfo(
        "Continue Linking of Documents before Editing Certificate",
        hasPendingTestReportFailedMessage
      );
    } else {
      setModalHeader("Modify Certificate");
      setIsModifyCert(true);
    }
  };

  const copyCertificate = async () => {
    setIsDisable(true);
    setLoading(true);

    const detail = await CertificateListService.getCertificateDetails(
      selectedData.certificateId
    );
    detail.certificateId = selectedData.certificateId;
    detail.localData = detail.localData && JSON.parse(detail?.localData)[0];
    ifECMClearTheOldAttachmentImplementation(detail?.localData);
    setDetails(detail);
    setIsOpenCopy(true);

    dispatch(updatePartySiteNumber(selectedData.ownerReference));
    setIsDisable(false);
    setLoading(false);
  };

  const loadDetails = async () => {
    setIsDisable(true);
    setLoading(true);
    setRevisionNumber(selectedData.revisionNumber);

    const detail = await CertificateListService.getCertificateDetails(
      selectedData.certificateId
    );

    let hasPendingTestReport = false;

    if (FeatureFlagService.getECMFeatureFlag()) {
      const certificateDetailFromE2E =
        await CertificateListService.getCertificateDetailByWorkbenchCertificateId(
          selectedData.certificateId
        );

      if (certificateDetailFromE2E.data?.baseModel) {
        detail.assets.forEach((asset) => {
          if (asset.modelName == certificateDetailFromE2E.data.baseModel) {
            asset.isBaseModel = true;
            return;
          }
        });
      }

      var attachments = await getAttachments(
        certificateDetailFromE2E?.data?.workbenchUniqueCertificateId
      );

      if (
        attachments?.filter(
          (attachment) =>
            attachment.status ==
            CertificateDocumentStatus.TestReportDuplicateLinkToEcmFailed
        ).length > 0
      ) {
        hasPendingTestReport = true;
      }
    }

    if (hasPendingTestReport) {
      setIsDisable(false);
      setLoading(false);

      return hasPendingTestReport;
    }

    detail.certificateId = selectedData.certificateId;
    detail.localData = detail.localData && JSON.parse(detail.localData)[0];
    ifECMClearTheOldAttachmentImplementation(detail.localData);
    setDetails(detail);
    populateUploads(detail);

    await populateProjectDetails(detail?.projectNumber, detail?.localData?.line_number);

    dialogFuncMap["displayAddNewCertModal"](true);

    dispatch(updateCertificateScheme(detail.certificateScheme));
    dispatch(updateProdDetailsModelList(detail.assets));
    dispatch(updateModelList(detail.assets));
    dispatch(updatePartySiteNumber(selectedData.ownerReference));
    setIsDisable(false);
    setLoading(false);

    return hasPendingTestReport;
  };
  const ifECMClearTheOldAttachmentImplementation = (data) => {
    if (FeatureFlagService.getECMFeatureFlag() && data && data.attachments)
      data.attachments = null;
  };
  const getAttachments = async (uniqueCertificateId) => {
    if (!uniqueCertificateId) return;
    const attachments =
      await CertificateListService.getCertificateDocumentsByUniqueCertificateId(
        uniqueCertificateId
      );
    setAttachments(attachments?.data);
    return attachments?.data;
  };
  const populateProjectDetails = async (projectNumber, lineNumber) => {
    const projectNumbers = await ProjectDetailsService.getProjectSuggestions(
      projectNumber
    );

    if (projectNumbers.length === 0) return;

    let updatedSelectedProjectDetails;

    const item = projectNumbers[0];
    const data = await ProjectDetailsService.getProjectDetail(
      item.project_Number
    );

    let tempLineDetails = [];
    data.value.forEach((d) => {
      //Filter - Get Child Lines
      const lineDatas = d.projectServiceLines.filter(
        (l) => l.lineNumber.split(".").length === 3
      );

      if (lineDatas.length === 0) {
        return;
      }

      for (var i = 0; i < lineDatas.length; i++) {
        const projectContacts = getProjectContact(d.projectContacts);

        lineDatas[i].partySiteNumber = projectContacts.partySiteNumber;
        lineDatas[i].companyId = d.companyId;
        lineDatas[i].address = projectContacts.address;
        lineDatas[i].city = projectContacts.city;
        lineDatas[i].state = projectContacts.state;
        lineDatas[i].province = projectContacts.province;
        lineDatas[i].country = projectContacts.country;
        lineDatas[i].postalcode = projectContacts.postalCode;
        lineDatas[i].partysite = projectContacts.partySiteNumber;
        lineDatas[i].customer = d.companyName;
        lineDatas[i].poc = projectContacts.country;
        lineDatas[i].handlerName = d.projectHandler;
        lineDatas[i].fulfillmentLocation =
          item.preferred_Fulfillment_Location;
        lineDatas[i].projectFlexId = d.projectId;
        lineDatas[i].projectNumber = d.projectNumber;
        lineDatas[i].orderNumber = d.orderNumber;
        lineDatas[i].quoteNo = d.quoteNo;
        lineDatas[i].projectName = d.name;
      }

      tempLineDetails = tempLineDetails.concat(lineDatas);
    });
    tempLineDetails = tempLineDetails.filter((l) => l != null);

    const lineNumbers = tempLineDetails.map((d) => d.lineNumber);
    const sortedLineNumbers = lineNumbers
      .map((a) =>
        a
          .split(".")
          .map((n) => +n + 100000)
          .join(".")
      )
      .sort()
      .map((a) =>
        a
          .split(".")
          .map((n) => +n - 100000)
          .join(".")
      );

    const mappedLines = sortedLineNumbers.map((d) => {
      var details = tempLineDetails.find((l) => l.lineNumber === d);
    return {
      name:
        (details?.isCpq ? details?.parentLineNumber : d) +
        " - " +
        details?.projectName,
      value: d,
    };
    });

    const selectedProjectDetails = tempLineDetails.find(
      (t) => t.lineNumber === lineNumber
    );

    if (selectedProjectDetails) {
      updatedSelectedProjectDetails = await getUserDetails(
        selectedProjectDetails
      );
    } else {
      const tempLineDetailData = tempLineDetails.find((line) => line.lineNumber === mappedLines[0].value);

      updatedSelectedProjectDetails = await getUserDetails(
        tempLineDetailData
      );
    }

    await getServiceLine(updatedSelectedProjectDetails);

    dispatch(updateLineDetails(tempLineDetails));
    dispatch(updateOrderLineNumbers(mappedLines));
    dispatch(updateSelectedProjectDetails(updatedSelectedProjectDetails));
    dispatch(updateSelectedOrderLineNumber(lineNumber ?? mappedLines[0].value));
  };

  const getServiceLine = async(selectedDetailsToUpdate) => {
    const result = await ProjectDetailsService.getServiceLine(selectedDetailsToUpdate.projectId);
    selectedDetailsToUpdate.serviceLine = result.service_Line;
  }

  const getUserDetails = async (selectedProjectDetails) => {
    if (!selectedProjectDetails || !selectedProjectDetails.handlerName)
      return selectedProjectDetails;

    const response = await ProjectDetailsService.getUserDetails(
      selectedProjectDetails.handlerName
    );
    if (!response) return;

    const handlerCountry = response.split("-")[0];
    const handlerLocation = response.split("-")[1];

    selectedProjectDetails.handlerCountry = handlerCountry;
    selectedProjectDetails.handlerLocation = handlerLocation;
    return selectedProjectDetails;
  };

  const populateUploads = (detail) => {
    if (FeatureFlagService.getECMFeatureFlag()) return;
    if (!detail.localData?.attachments) return;

    const certificateAttachments = detail.localData?.attachments
      .filter((a) => a.attachmentType === "Certificate")
      .map((a) => ({
        fileResult: "",
        id: a.id,
        isDeleting: false,
        isRemovedFromDb: false,
        isRemovedFromUI: false,
        isUploaded: true,
        isValid: true,
        isUploading: false,
        name: a.fileName,
        uploadPercent: 100,
      }));

    const testReportAttachments = detail.localData?.attachments
      .filter((a) => a.attachmentType === "Test Reports")
      .map((a) => ({
        fileResult: "",
        id: a.id,
        isDeleting: false,
        isRemovedFromDb: false,
        isRemovedFromUI: false,
        isUploaded: true,
        isValid: true,
        isUploading: false,
        name: a.fileName,
        uploadPercent: 100,
      }));

    const othersAttachments = detail.localData?.attachments
      .filter((a) => a.attachmentType === "Others")
      .map((a) => ({
        fileResult: "",
        id: a.id,
        isDeleting: false,
        isRemovedFromDb: false,
        isRemovedFromUI: false,
        isUploaded: true,
        isValid: true,
        isUploading: false,
        name: a.fileName,
        uploadPercent: 100,
      }));

    const attachments = [
      certificateAttachments,
      testReportAttachments,
      othersAttachments,
    ];

    dispatch(updateProdDetailsAttachments(attachments));
  };

  const getProjectContact = (projectContacts) => {
    const soldTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.SoldTo
    );
    if (soldTo?.partySiteNumber) return soldTo;

    const shipTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.ShipTo
    );
    if (shipTo?.partySiteNumber) return shipTo;

    const customer = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.Customer
    );
    if (customer?.partySiteNumber) return customer;

    const billTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.BillTo
    );
    if (billTo?.partySiteNumber) return billTo;
  };

  return (
    <>
      <GridActionPopover
        ref={childPopOverRef}
        actionList={actionList}
        isDisable={isDisable}
      ></GridActionPopover>

      <AddNewCertificate
        dialogFuncMap={dialogFuncMap}
        displayAddNewCertModal={displayAddNewCertModal}
        isEditCert={true}
        isModifyCert={isModifyCert}
        setIsModifyCert={setIsModifyCert}
        certificateId={details?.certificateId}
        certificateScheme={details?.certificateScheme}
        revisionNumber={revisionNumber}
        editData={details?.editData}
        localData={details?.localData}
        certificateStatus={details?.certificateStatus}
        hasRevisions={details?.revisionHistory.length > 0}
        setShowCertificateDetails={setShowCertificateDetails}
        customModalHeader={modalHeader}
        hierarchyId={details?.hierarchyId}
        inputAttachments={attachments}
      />
      <WithdrawCertificateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        certificateId={certificateId}
        hasRevisionNumber={hasRevisionNumber}
        revisionNumber={revisionNumber}
      />
      <CopyCertificateModal
        isOpen={isOpenCopy}
        setIsOpen={setIsOpenCopy}
        details={details}
        setShowCertificateDetails={setShowCertificateDetails}
      />
    </>
  );
};

export default GridAction;
