/* eslint-disable react-hooks/exhaustive-deps */
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addBusinessDays } from "date-fns";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import { max, sortBy, uniqBy } from "lodash";
import moment from "moment";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect, MultiSelectChangeParams } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import useToastr from "../../../../../hooks/useToastr";
import { authProvider } from "../../../../../providers/authProvider";
import FlexIntegrationService from "../../../../../services/FlexIntegrationService";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import ProjectService from "../../../../../services/ProjectService";
import {
  enumerateDaysBetweenDates,
  getUniqueDates,
} from "../../../../../shared/date-helper/DateHelper";
import { AdHocTaskFlexProjectAssociationOptionModel } from "../../../../../shared/models/AdHocTaskFlexProjectAssociationOption.model";
import {
  AdHocTaskFlexProjectAssociationModel,
  CreateAdHocTaskModel,
} from "../../../../../shared/models/CreateAdHocTaskModel";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import { PredefinedTask } from "../../../../../shared/models/PredefinedTask.model";
import { GMATCTaskName } from "../../../../../utils/constants/flex-task-name.constants";
import { CustomerVisibility } from "../../enums/customer-visibility";
import HandlerType from "../../enums/handler-type";
import ProjectOrderlineStatus from "../../enums/project-orderline-status";
import ProjectTaskStatus from "../../enums/project-task-status";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import {
  AdhocStatusDropdown,
  CalculateAdhocStatusDropdown,
} from "../../utils/AdhocUtils";
import ProjectCollaboratorAssignmentDropdown from "../project-collaborator-assignment-dialog/project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown";
import { ProjectUserSource } from "../project-collaborator-assignment-dialog/ProjectCollaboratorAssignmentDialog";
import { adHocTaskSchema } from "./ad-hoc-task-schema";
import classes from "./AdHocTaskFormDialog.module.scss";
import FlexProjectAssociationsDataTable from "./flex-project-associations-data-table/FlexProjectAssociationsDataTable";
import CustomerVisibleIndicator, {
  CustomerVisibilityLegend,
} from "../customer-visible-indicator/CustomerVisibleIndicator";

const initialHandlerDropdownOptions: ProjectUserSource = {
  currentSkip: 0,
  currentTake: 5,
  currentSearchPhrase: "",
  data: [],
};

const customerVisiblityOptions = [
  { label: "Not Visible", value: CustomerVisibility.NotVisible },
  { label: "Visible", value: CustomerVisibility.Visible },
];

type Props = {
  visible: boolean;
  onHide: () => void;
  onNewTaskCreated: () => void;
  e2eProjectId: string;
  e2eProjectName: string;
  flexCorellationId: string | null;
};

const AdHocTaskFormDialog = (props: Props) => {
  const initialAdHocTaskValue: CreateAdHocTaskModel = {
    e2eProjectId: props.e2eProjectId,
    taskName: "",
    taskHandler: null as E2EUserPaginatedModel | null,
    shortDescription: "",
    milestoneName: "",
    milestoneHandler: null as E2EUserPaginatedModel | null,
    orderLineAssociations: [] as string[],
    predecessor: null,
    flexTaskStatus: null as ProjectTaskStatus | null,
    onHoldReason: null,
    onHoldReasonStatusDetailId: null,
    startDate: null,
    dueDate: undefined as Date | undefined,
    customerVisibility: CustomerVisibility.NotVisible,
    comment: "",
    minStartDate: null,
    predefinedTaskTypeId: null as null | string,
    flexProjectsAssociationDetails: [],
  };

  const formik = useFormik({
    initialValues: initialAdHocTaskValue,
    onSubmit: handleSubmit,
    validationSchema: adHocTaskSchema,
  });

  const {
    setFieldValue,
    values,
    setValues,
    resetForm,
    submitForm,
    isSubmitting,
  } = formik;
  const [flexHandlerDropdownOptions, setFlexHandlerDropdownOptions] =
    useState<ProjectUserSource>(initialHandlerDropdownOptions);
  const [milestoneHandlerDropdownOptions, setMilestoneHandlerDropdownOptions] =
    useState<ProjectUserSource>(initialHandlerDropdownOptions);
  const [holdReasonOptions, setHoldReasonOptions] = useState<
    PmrE2EOnHoldReason[]
  >([]);
  const [predefinedTasks, setPredefinedTasks] = useState<PredefinedTask[]>([]);
  const [predefinedTasksSuggestions, setPredefinedTasksSuggestions] =
    useState<PredefinedTask[]>();
  const [predecessorOptions, setPredecessorOptions] = useState<string[]>([]);
  const { showSuccess, showError } = useToastr();
  const [e2eProjectLines, setE2EProjectLines] = useState<
    AdHocTaskFlexProjectAssociationOptionModel[]
  >([]);
  const [flexStatusDropdownOptions, setFlexStatusDropdownOptions] = useState<
    AdhocStatusDropdown[]
  >(
    CalculateAdhocStatusDropdown({
      startDate: values.startDate,
      taskHandler: values.taskHandler,
    })
  );

  const userInfo = authProvider.getAccountInfo();
  const userAsHandlerDetails: E2EUserPaginatedModel = {
    displayName: userInfo?.account.name || "",
    normalizedEmail: userInfo?.account.idToken.email || "",
  };

  const isStartDateDisabled = values.predecessor?.length! > 0;

  useEffect(() => {
    if (props.visible) {
      resetForm();
      prepopulateWithDefaultValues();
      fetchInitialTaskHandlerOptions();
      fetchHoldReasonOptions();
      fetchPredefinedTasks();
      fetchInitialMilestoneHandlerOptions();
      fetchE2EProjectLines();
    }
  }, [props.visible]);

  useEffect(() => {
    const orderBookedDates = e2eProjectLines
      .filter((projectLine) =>
        values.orderLineAssociations.includes(projectLine.id!)
      )
      .map((projectLine) => projectLine.orderBookedDate);

    const minStartDate = max(orderBookedDates);
    setFieldValue("minStartDate", minStartDate);
  }, [values.orderLineAssociations]);

  useEffect(() => {
    if (values.flexTaskStatus !== ProjectTaskStatus.OnHold) {
      setFieldValue("onHoldReason", null);
    }
  }, [values.flexTaskStatus]);

  useEffect(() => {
    fetchPredecessorOptions();
  }, [values.orderLineAssociations]);

  useEffect(() => {
    const statusOptions = CalculateAdhocStatusDropdown({
      startDate: values.startDate,
      taskHandler: values.taskHandler,
    });
    setFlexStatusDropdownOptions(statusOptions);
    if (
      !statusOptions.some((option) => option.value === values.flexTaskStatus)
    ) {
      setFieldValue("flexTaskStatus", statusOptions[0].value);
    }
  }, [values.startDate, values.taskHandler]);

  useEffect(() => {
    if (values.predecessor?.length) {
      setFieldValue("startDate", null);
    }
  }, [values.predecessor]);

  const fetchE2EProjectLines = async () => {
    const result =
      await ProjectService.getAdHocTaskFlexProjectAssociationOptions(
        props.e2eProjectId,
        props.flexCorellationId
      );
    setE2EProjectLines(result.data);
  };

  const getOnHoldReasonStatusDetailsId = (
    onHoldReason: string | null | undefined
  ) => {
    return onHoldReason
      ? holdReasonOptions.find(
          (reason) => reason.taskStatusReason === onHoldReason
        )?.taskStatusDetailId
      : null;
  };

  const parseDate = (date: string | Date | null | undefined) => {
    return date ? moment(date).format("YYYY-MM-DD") : null;
  };

  async function handleSubmit() {
    const model: CreateAdHocTaskModel = {
      ...values,
      flexProjectsAssociationDetails:
        values.flexProjectsAssociationDetails.length > 0
          ? values.flexProjectsAssociationDetails.map((association) => ({
              ...association,
              orderLineAssociation: association.id!,
              startDate: parseDate(association.startDate),
              dueDate: parseDate(association.dueDate),
              duration: getDuration(association.startDate, association.dueDate),
              onHoldReasonStatusDetailId: getOnHoldReasonStatusDetailsId(
                association.onHoldReason
              ),
            }))
          : [
              {
                startDate: parseDate(values.startDate),
                dueDate: parseDate(values.dueDate),
                duration: getDuration(values.startDate, values.dueDate),
                onHoldReason: values.onHoldReason,
                onHoldReasonStatusDetailId: getOnHoldReasonStatusDetailsId(
                  values.onHoldReason
                ),
                orderLineAssociation: values.orderLineAssociations[0] as string,
                flexTaskStatus: values.flexTaskStatus,
              },
            ],
    };

    try {
      await ProjectService.createAdHocTask(model);
      showSuccess(
        "Success",
        `Ad Hoc Task <b>'${values.taskName}'</b> for <b>'${props.e2eProjectName}'</b> successfully created.`
      );

      props.onNewTaskCreated();
    } catch (error) {
      showError(
        "Error",
        "Failed to create Ad Hoc Task. Please try again. Contact support team if issue persists."
      );
    }
  }

  const prepopulateWithDefaultValues = () => {
    const statusOptions = CalculateAdhocStatusDropdown({
      startDate: values.startDate,
      taskHandler: values.taskHandler,
    });

    setValues(initialAdHocTaskValue);
    setFieldValue("flexTaskStatus", statusOptions[0].value);
    setFieldValue("customerVisibility", CustomerVisibility.NotVisible);
  };

  const fetchPredefinedTasks = () => {
    FlexIntegrationService.getAllPredefinedTasks().then((result) => {
      setPredefinedTasks(result.data);
    });
  };

  const fetchInitialTaskHandlerOptions = () => {
    GMAWBUsersService.getCOHandlerPaginatedUsers(
      0,
      5,
      HandlerType.TaskHandler,
      false,
      ""
    ).then((response) => {
      let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
      setFlexHandlerDropdownOptions((current) => ({
        ...current,
        data: flexData || [],
      }));
    });
  };

  const fetchInitialMilestoneHandlerOptions = () => {
    GMAWBUsersService.getCOHandlerPaginatedUsers(
      0,
      5,
      HandlerType.MilestoneHandler,
      false,
      ""
    ).then((response) => {
      let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
      setMilestoneHandlerDropdownOptions((current) => ({
        ...current,
        data: flexData || [],
      }));
    });
  };

  const fetchHandler = async (email: string, handlerType: HandlerType) => {
    const result = await GMAWBUsersService.getCOHandlerPaginatedUsers(
      0,
      1,
      handlerType,
      false,
      email
    );

    return result.data[0];
  };

  const fetchHoldReasonOptions = async () => {
    ProjectService.getTaskOnHoldStatus().then((holdReasons) => {
      setHoldReasonOptions(holdReasons);
    });
  };

  const fetchPredecessorOptions = async () => {
    setPredecessorOptions([]);
    values.predecessor = [];
    if (!values.orderLineAssociations?.length) return;

    const result = await ProjectService.getE2EProjectLinesCommonTasks(
      values.orderLineAssociations
    );
    setPredecessorOptions(result.data.filter((task) => task !== GMATCTaskName));
  };

  const dialogHeader = "Add Ad Hoc Task";
  const dialogFooter = (
    <div className={classes["dialog-footer"]}>
      <Button
        disabled={isSubmitting}
        onClick={() => props.onHide()}
        className={classes["btn-cancel"]}
      >
        CANCEL
      </Button>
      <Button
        loading={isSubmitting}
        className={classes["btn-save"]}
        onClick={submitForm}
        disabled={!formik.isValid}
      >
        SAVE
      </Button>
    </div>
  );

  const isOnHoldReasonEnabled =
    values.flexTaskStatus === ProjectTaskStatus.OnHold;

  const getDuration = (
    startDate: string | Date | null | undefined,
    dueDate: string | Date | null | undefined
  ) => {
    if (!startDate || !dueDate) return 0;
    if (dueDate < startDate) return 0;

    const dates = getUniqueDates([
      startDate as Date,
      dueDate as Date,
      ...enumerateDaysBetweenDates(startDate as Date, dueDate as Date),
    ]);

    return dates.filter((date) => date.getDay() !== 6 && date.getDay() !== 0)
      .length;
  };

  const searchPredefinedTasks = ({
    query: search,
  }: AutoCompleteCompleteMethodParams) => {
    if (!search) {
      setPredefinedTasksSuggestions([]);
      return;
    }

    const filtered =
      predefinedTasks?.filter((task) =>
        task.name.toUpperCase().startsWith(search.toUpperCase())
      ) || [];

    const sorted = sortBy(filtered, "name");

    setPredefinedTasksSuggestions(sorted);
  };

  const handlePredefinedTaskSelected = async (e: AutoCompleteSelectParams) => {
    const selectedPredefinedTask: PredefinedTask = e.value;

    setFieldValue("taskName", selectedPredefinedTask.name);
    setFieldValue("milestoneName", "UL: " + selectedPredefinedTask.name);
    setFieldValue("shortDescription", selectedPredefinedTask.description || "");
    setFieldValue("predefinedTaskTypeId", selectedPredefinedTask.taskTypeId);

    if (selectedPredefinedTask.estimatedDurationDays !== null) {
      const newStartDate = new Date();
      const newDueDate = addBusinessDays(
        newStartDate,
        (selectedPredefinedTask.estimatedDurationDays || 0) - 1
      );

      setFieldValue("startDate", newStartDate);
      setFieldValue("dueDate", newDueDate);
    }

    if (selectedPredefinedTask.taskOwner) {
      const taskHandler = await fetchHandler(
        selectedPredefinedTask.taskOwner,
        HandlerType.TaskHandler
      );

      if (taskHandler) {
        setFieldValue("taskHandler", taskHandler);
        setFieldValue("milestoneHandler", taskHandler);
      }
    }
  };

  const handleTaskNameChanged = (e: AutoCompleteChangeParams) => {
    setFieldValue("taskName", e.value);
    setFieldValue("milestoneName", "UL: " + e.value);
  };

  const predefinedTaskSuggestionItemTemplate = (item: PredefinedTask) => {
    return (
      <div className={classes["predefined-task-suggestion-item"]}>
        <div>{item.name}</div>
        {item.description && (
          <div className={classes["predefined-task-description"]}>
            {item.description}
          </div>
        )}
      </div>
    );
  };

  const orderLineAssociationOptions = (e2eProjectLines || []).map(
    (projectLine: AdHocTaskFlexProjectAssociationOptionModel) => ({
      label: `${projectLine.orderLineDescription} | ${projectLine.flexProjectNumber} | ${projectLine.flexProjectName}`,
      value: projectLine.id,
      flexProjectStatus: projectLine.flexProjectStatus,
    })
  );

  const constructFlexHandlerDropdownOptions = () => {
    let result = flexHandlerDropdownOptions.data;

    if (!!values.taskHandler?.normalizedEmail) {
      result = [values.taskHandler, ...flexHandlerDropdownOptions?.data];
    }

    result = uniqBy(result, "normalizedEmail");

    return {
      ...flexHandlerDropdownOptions,
      data: result,
    } as ProjectUserSource;
  };

  const constructMilestoneHandlerDropdownOptions = () => {
    let result = milestoneHandlerDropdownOptions.data;

    if (!!values.taskHandler?.normalizedEmail) {
      result = [values.taskHandler, ...milestoneHandlerDropdownOptions?.data];
    }

    result = uniqBy(result, "normalizedEmail");

    return {
      ...milestoneHandlerDropdownOptions,
      data: result,
    } as ProjectUserSource;
  };

  const constructLabel = (fieldName: string) => {
    const details = adHocTaskSchema.describe().fields[fieldName] as any;

    const isRequired = details.tests.some(
      ({ name }: any) => name === "required"
    );

    const customerVisibleFieldNames = [
      "taskName",
      "flexTaskStatus",
      "startDate",
      "dueDate",
      "comment",
      "onHoldReason",
    ];

    const isCustomerVisibleField = customerVisibleFieldNames.some(
      (fields) => fields === fieldName
    );

    return (
      <>
        {isCustomerVisibleField && (
          <span title="This field is visible to customers.">
            <CustomerVisibleIndicator
              isRequiredField={isRequired}
              suppressTooltip
            >
              {details.label}
            </CustomerVisibleIndicator>
          </span>
        )}
        {!isCustomerVisibleField && (
          <label className={classNames(isRequired && classes["required"])}>
            {details.label}
          </label>
        )}
      </>
    );
  };

  const handleStartDateChange = (e: CalendarChangeParams) => {
    setFieldValue("startDate", e.value);

    if (!e.value) return;

    const startDate = moment(e.value as Date)
      .startOf("day")
      .toDate();
    const today = moment(new Date()).startOf("day").toDate();

    if (startDate > today) {
      setFieldValue("flexTaskStatus", ProjectTaskStatus.NotStarted);
    } else {
      setFieldValue("flexTaskStatus", ProjectTaskStatus.InProgress);
    }
  };

  const handleOrderLineAssociationsChange = (e: MultiSelectChangeParams) => {
    setFieldValue("orderLineAssociations", e.value);

    const selectedLines = e2eProjectLines.filter((line) =>
      e.value.includes(line.id)
    );

    if (selectedLines.length <= 1) {
      setFieldValue("flexProjectsAssociationDetails", []);
    } else {
      setFieldValue(
        "flexProjectsAssociationDetails",
        selectedLines.map((line) => {
          return {
            ...line,
            flexTaskStatus: ProjectTaskStatus.NotScheduled,
            dueDate: null,
            startDate: null,
            onHoldReason: null,
            ...(values.flexProjectsAssociationDetails.find(
              (association) => association.id === line.id
            ) || {}),
          };
        })
      );
    }
  };

  return (
    <Dialog
      className={classes["dialog"]}
      header={dialogHeader}
      footer={dialogFooter}
      visible={props.visible}
      onHide={props.onHide}
    >
      <div style={{ marginTop: "1rem" }} />
      <CustomerVisibilityLegend />
      <FormikProvider value={formik}>
        <Form className={classes["form"]}>
          <div className={classes["row"]}>
            <Field name="taskName">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["task-name-field"],
                    classes["field"],
                    classes["customer-visible-field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <AutoComplete
                    {...field}
                    onChange={handleTaskNameChanged}
                    className={classNames(
                      "form-control",
                      meta.touched && meta.error && "p-invalid"
                    )}
                    itemTemplate={predefinedTaskSuggestionItemTemplate}
                    completeMethod={searchPredefinedTasks}
                    onSelect={handlePredefinedTaskSelected}
                    suggestions={predefinedTasksSuggestions}
                    maxLength={250}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="taskHandler">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["task-handler-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <ProjectCollaboratorAssignmentDropdown
                    showClear={true}
                    className={classNames(
                      meta.touched && meta.error && "p-invalid"
                    )}
                    onBlur={field.onBlur}
                    value={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);
                      setFieldValue("milestoneHandler", value);
                    }}
                    isDisabled={false}
                    dropdownOptions={constructFlexHandlerDropdownOptions()}
                    setDropdownOptions={setFlexHandlerDropdownOptions}
                    handlerType={HandlerType.FlexHandler}
                    isGmaTcTask={false}
                    includeCOHandlers={true}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>
          </div>

          <Field name="shortDescription">
            {({ field, meta }: FieldProps) => (
              <div className={classNames(classes["field"])}>
                {constructLabel(field.name)}

                <InputText
                  {...field}
                  disabled={!!values.predefinedTaskTypeId}
                  className={classNames(
                    classes["form-control"],
                    meta.touched && meta.error && "p-invalid"
                  )}
                />

                <ErrorMessage
                  component="div"
                  className={classes["invalid-error-message"]}
                  name={field.name}
                />
              </div>
            )}
          </Field>

          <div className={classes["row"]}>
            <Field name="milestoneName">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["milestone-name-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <InputText
                    {...field}
                    disabled
                    className={classNames(
                      classes["form-control"],
                      meta.touched && meta.error && "p-invalid"
                    )}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="milestoneHandler">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["milestone-handler-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <ProjectCollaboratorAssignmentDropdown
                    showClear={true}
                    className={classNames(
                      meta.touched && meta.error && "p-invalid"
                    )}
                    onBlur={field.onBlur}
                    value={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);
                    }}
                    isDisabled={false}
                    dropdownOptions={constructMilestoneHandlerDropdownOptions()}
                    setDropdownOptions={setMilestoneHandlerDropdownOptions}
                    handlerType={HandlerType.MilestoneHandler}
                    isGmaTcTask={false}
                    includeCOHandlers={true}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>
          </div>

          <div className={classes["row"]}>
            <Field name="orderLineAssociations">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["order-line-association-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <Tooltip
                    target=".flex-project-association-info-icon"
                    className={classes["flex-project-association-info-tooltip"]}
                  >
                    Ad Hoc Task will be directly associated with the FLEX
                    Project of the selected order line(s). Order lines with a{" "}
                    <b>'Completed'</b> FLEX Project Status will be disabled.
                  </Tooltip>

                  <FontAwesomeIcon
                    className="flex-project-association-info-icon"
                    style={{ marginLeft: ".25rem", cursor: "pointer" }}
                    icon={faInfoCircle}
                  />

                  <MultiSelect
                    {...field}
                    optionDisabled={(
                      option: AdHocTaskFlexProjectAssociationOptionModel
                    ) =>
                      option.flexProjectStatus ===
                      ProjectOrderlineStatus.Completed
                    }
                    placeholder="-- SELECT --"
                    className={classNames(
                      classes["form-control"],
                      meta.touched && meta.error && "p-invalid"
                    )}
                    filter
                    selectedItemsLabel="All"
                    options={orderLineAssociationOptions}
                    optionLabel="label"
                    optionValue="value"
                    maxSelectedLabels={orderLineAssociationOptions.length - 1}
                    onChange={handleOrderLineAssociationsChange}
                    appendTo={"self"}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="predecessor">
              {({ field }: FieldProps) => (
                <div
                  className={classNames(
                    classes["predecessor-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}
                  <Tooltip
                    target=".predecessor-info-icon"
                    className={classes["flex-project-association-info-tooltip"]}
                  >
                    The Predecessor dynamically updates its selection to reflect
                    all common FLEX Tasks and Ad Hoc Tasks to the selected
                    associated FLEX Projects. Tasks marked as 'Completed' or
                    'Cancelled' on any FLEX Project cannot be selected as
                    predecessors.
                  </Tooltip>

                  <FontAwesomeIcon
                    className="predecessor-info-icon"
                    style={{ marginLeft: ".25rem", cursor: "pointer" }}
                    icon={faInfoCircle}
                  />

                  <MultiSelect
                    {...field}
                    disabled={!values.orderLineAssociations.length}
                    placeholder="-- SELECT --"
                    filter
                    selectedItemsLabel="All"
                    options={predecessorOptions}
                    appendTo={"self"}
                  />
                </div>
              )}
            </Field>
          </div>

          {values.flexProjectsAssociationDetails.length > 0 && (
            <FlexProjectAssociationsDataTable
              holdReasonOptions={holdReasonOptions}
            />
          )}

          {values.flexProjectsAssociationDetails.length === 0 && (
            <>
              <div className={classes["row"]}>
                <Field name="startDate">
                  {({ field, meta }: FieldProps) => (
                    <div
                      className={classNames(
                        classes["start-date-field"],
                        classes["field"],
                        classes["customer-visible-field"]
                      )}
                    >
                      {constructLabel(field.name)}

                      <Calendar
                        {...field}
                        minDate={
                          values.minStartDate
                            ? new Date(values.minStartDate)
                            : undefined
                        }
                        showButtonBar
                        onChange={handleStartDateChange}
                        monthNavigator
                        yearNavigator
                        yearRange={"2018:2030"}
                        className={classNames(
                          meta.error && meta.touched && "p-invalid"
                        )}
                        readOnlyInput
                        disabled={isStartDateDisabled}
                        appendTo={"self"}
                      />

                      <ErrorMessage
                        component="div"
                        className={classes["invalid-error-message"]}
                        name={field.name}
                      />
                    </div>
                  )}
                </Field>

                <Field name="dueDate">
                  {({ field, meta }: FieldProps) => (
                    <div
                      className={classNames(
                        classes["due-date-field"],
                        classes["field"],
                        classes["customer-visible-field"]
                      )}
                    >
                      {constructLabel(field.name)}

                      <Calendar
                        {...field}
                        showButtonBar
                        monthNavigator
                        yearNavigator
                        yearRange={"2018:2030"}
                        minDate={values.startDate as Date}
                        className={classNames(
                          meta.error && meta.touched && "p-invalid"
                        )}
                        readOnlyInput
                        appendTo={"self"}
                      />

                      <ErrorMessage
                        component="div"
                        className={classes["invalid-error-message"]}
                        name={field.name}
                      />
                    </div>
                  )}
                </Field>

                <div
                  className={classNames(
                    classes["duration-field"],
                    classes["field"]
                  )}
                >
                  <label>Duration</label>
                  <InputText
                    value={getDuration(values.startDate, values.dueDate)}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={classes["row"]}>
                <Field name="flexTaskStatus">
                  {({ field, meta }: FieldProps) => (
                    <div
                      className={classNames(
                        classes["flex-task-status-field"],
                        classes["field"],
                        classes["customer-visible-field"]
                      )}
                    >
                      {constructLabel(field.name)}

                      <Tooltip
                        target=".flex-task-status-info-icon"
                        className={
                          classes["flex-project-association-info-tooltip"]
                        }
                      >
                        <span>
                          "FLEX Task Status updates automatically based on the
                          state of selected predecessors for each associated
                          FLEX Project."
                        </span>
                      </Tooltip>

                      <FontAwesomeIcon
                        className="flex-task-status-info-icon"
                        style={{ marginLeft: ".25rem", cursor: "pointer" }}
                        icon={faInfoCircle}
                      />

                      <Dropdown
                        placeholder="-- SELECT --"
                        disabled={flexStatusDropdownOptions.length <= 1}
                        inputId={field.name}
                        {...field}
                        className={classNames(
                          meta.touched && meta.error && "p-invalid"
                        )}
                        options={flexStatusDropdownOptions}
                        optionLabel="label"
                        optionValue="value"
                        appendTo={"self"}
                      />

                      <ErrorMessage
                        component="div"
                        className={classes["invalid-error-message"]}
                        name={field.name}
                      />
                    </div>
                  )}
                </Field>

                <Field name="onHoldReason">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <div
                        className={classNames(
                          classes["on-hold-reason-field"],
                          classes["field"],
                          classes["customer-visible-field"]
                        )}
                      >
                        {constructLabel(field.name)}

                        <Dropdown
                          inputId={field.name}
                          {...field}
                          name={"onHoldReason"}
                          className={classNames(
                            meta.touched && meta.error && "p-invalid"
                          )}
                          disabled={!isOnHoldReasonEnabled}
                          placeholder="--SELECT--"
                          options={holdReasonOptions}
                          optionValue="taskStatusReason"
                          optionLabel="taskStatusReason"
                          appendTo={"self"}
                        />

                        <ErrorMessage
                          component="div"
                          className={classes["invalid-error-message"]}
                          name={field.name}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>
            </>
          )}

          <Field name="customerVisibility">
            {({ field, meta }: FieldProps) => (
              <div
                className={classNames(
                  classes["customer-visibility-field"],
                  classes["field"],
                  classes["customer-visible-field"]
                )}
              >
                {constructLabel(field.name)}

                <Dropdown
                  inputId={field.name}
                  {...field}
                  className={classNames(
                    meta.touched && meta.error && "p-invalid"
                  )}
                  options={customerVisiblityOptions}
                  optionLabel="label"
                  optionValue="value"
                  appendTo={"self"}
                />

                <ErrorMessage
                  component="div"
                  className={classes["invalid-error-message"]}
                  name={field.name}
                />
              </div>
            )}
          </Field>

          <Field name="comment">
            {({ field }: FieldProps) => (
              <div
                className={classNames(
                  classes["comment-field"],
                  classes["field"],
                  classes["customer-visible-field"]
                )}
              >
                {constructLabel(field.name)}
                <InputTextarea {...field} rows={5} maxLength={512} autoResize />
              </div>
            )}
          </Field>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AdHocTaskFormDialog;
